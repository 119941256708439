let password = ''//密码
let newPasswordTest = ''//新密码(修改密码)



/* 验证账号 */

export function validateUsername(rule, value, callback) {
    if (value === '') {
        return callback(new Error('请输入'))
    } else if (value.indexOf(" ") != -1) {
        return callback(new Error('输入不能有空格'))
    } else {
        callback()
    }
}

/* 验证邮箱 */

export function validatEmailAddress(rule, value, callback) {
    let t = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/g;
    if (value === '') {
        return callback(new Error("请输入邮箱地址!"))
    } else if (!t.test(value)) {
        return callback(new Error("请输入正确邮箱地址!"))
    } else {
        callback()
    }
}

// 验证验证码
export function verificationCode(rule, value, callback) {
    if (value == '') {
        return callback(new Error("请输入验证码"));
    }
    if (value.length != 6) {
        return callback(new Error("请输入六位验证码"));
    }
    else {
        callback()
    }
}

/* 验证密码 */

export function validatePassword(rule, value, callback) {
    if (value == '') {
        return callback(new Error('请输入'))
    } else if (value.indexOf(" ") != -1) {
        return callback(new Error('输入不能有空格'))
    } else if (value.length < 6 || value.length > 18) {
        return callback(new Error('请输入6-18位密码'))
    } else {
        password = value
        callback()
    }
}

//修改密码 原密码验证
export function oldPsw(rule, value, callback) {
    if (value === '') {
        return callback(new Error("请输入原密码"))
    } else if (value.length < 6 || value.length > 18) {
        return callback(new Error("请输入6-18位的密码"))
    } else if (value.indexOf(" ") != -1) {
        return callback(new Error("输入不能有空格"));
    } else {
        password = value
        callback()
    }
}

//修改密码 新密码验证
export function newPsw(rule, value, callback) {
    if (value === '') {
        return callback(new Error("请输入新密码"))
    } else if (value.length < 6 || value.length > 18) {
        return callback(new Error("请输入6-18位的密码"))
    } else if (value.indexOf(" ") != -1) {
        return callback(new Error("输入不能有空格"));
    } else {
        newPasswordTest = value
        callback()
    }
}

// 验证重复密码
export function pwdAgainCheck(rule, value, callback) {
    if (value.length < 1) {
        return callback(new Error("重复密码不能为空!"));
    } else if (password != value) {
        return callback(new Error("两次输入密码不一致!"));
    } else {
        callback()
    }
}

//修改密码 确认新密码验证
export function confirmPsw(rule, value, callback) {
    if (value.length < 1) {
        return callback(new Error("请再次输入新密码"));
    } else if (newPasswordTest != value) {
        return callback(new Error("两次密码输入不一致"));
    } else {
        callback();
    }
}


/* 验证版本号 */
export function validatEdition(rule, value, callback) {
    let t = /^([1-9]\d|[1-9])(.([1-9]\d|\d)){2}$/;
    if (value === '') {
        return callback(new Error('请输入版本号'))
    } else if (!t.test(value)) {
        return callback(new Error('请输入正确格式版本号'))
    } else {
        callback()
    }
}

/* 验证6位字符串，唯一不重复，小写字母、数字、或组合 */

export function validateStr(rule, value, callback) {
    if (value === '') {
        return callback(new Error("输入不能为空"))
    } else if (value.length != 6) {
        return callback(new Error("请输入六位"))
    } else if (value.indexOf(" ") != -1) {
        return callback(new Error("不能输入空格"));
    } else {
        callback()
    }
}

/* 验证空格 */

export function validateSpace(rule, value, callback) {
    if (value.indexOf(" ") != -1) {
        return callback(new Error("输入不能有空格"))
    } else {
        callback()
    }
}