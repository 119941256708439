<template>
  <!-- 重置密码 -->
  <div class="forget-password">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="auto"
      class="demo-ruleForm"
      label-position="top"
    >
      <div class="title">设置密码</div>
      <el-form-item prop="password">
        <el-input
          class="restPassword"
          v-model="ruleForm.password"
          show-password
          placeholder="请输入密码"
        ></el-input>
      </el-form-item>
      <el-form-item prop="confirmPassword">
        <el-input
          class="restPassword"
          v-model="ruleForm.confirmPassword"
          show-password
          placeholder="再次确认密码"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          class="btn"
          :disabled="
            this.ruleForm.password == '' || this.ruleForm.confirmPassword == ''
          "
          type="primary"
          @click="submit()"
          >完成</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { validatePassword, pwdAgainCheck } from "../../utils/util/test";
import { mapState } from "vuex";
export default {
  data() {
    return {
      ruleForm: {
        mailAddress: null, // 邮箱
        confirmPassword: "", //确认密码
        password: "", // 密码
        trustCode: "", // code
        platformId:""
      },
      rules: {
        password: [{ validator: validatePassword, trigger: "blur" }],
        confirmPassword: [{ validator: pwdAgainCheck, trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.ruleForm.mailAddress = this.$route.query.mailAddress;
    this.ruleForm.trustCode = this.$route.query.trustCode;
  },
  computed: {
   
  },
  methods: {
    //提交
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        let that = this;
        if (valid) {
          this.request
            .resetSysUserPasswordByMail(this.ruleForm)
            .then(() => {
              this.$message.success("修改密码成功!");
              setTimeout(() => {
                this.$router.push({ path: "/login" });
              }, 1000);
            })
            .catch(() => {});
        }
      });
    },
  },
};
</script>

<style scoped>
.forget-password {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.demo-ruleForm {
  width: auto;
  margin-top: 200px;
}

.btn {
  margin-top: 97px;
  width: 100%;
  border-radius: 4px;
}
.title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 54px;
}
.restPassword {
  width: 386px;
  border-radius: 4px !important;
}
</style>